.payment-info-modal .modal-dialog-centered {
    max-width: 615px;
    margin-top: 30px;
    min-height: auto;
}

.payments-cards.d-flex.addedpayment-cards-wrap:last-child {
    margin-bottom: 0px;
}

.payments-cards.d-flex {
    max-width: 514px;
}

.payment-info-modal .payments-cards.d-flex {
    margin-bottom: 20px;
}

.payment-info-modal .card-right-side .tag {
    margin-right: 12px;
}

.no_payment_add_box span {
    font-size: 16px;
    line-height: 24px;
}

.no_payment_add_box {
    border-bottom: 0px;
    margin-bottom: 0;
    padding-bottom: 0px;
    margin-top: 0px !important;
}

.payment-info-modal .addcard-outer .add-paymentmethod-wrap .credit-card-field {
    max-width: calc(554px - 40px); 
    flex: calc(554px - 40px) 1;
}

.brVAre {
    display: block !important;
}

.addnewbutton-box {
    border-bottom: 0px;
    margin-top: 32px;
}

.bill-amount-box-inner {
    border: 1px solid #ccc;
    padding: 12px 20px;
    width: 200px;
    margin: 0 8px;
    border-radius: 10px;
}

.modal-bill-amount-wrap {
    margin: 32px -8px 32px;
}

.payment-info-modal  .addcardbuttonbox .primary-large{
    max-width: 514px;
}

@media screen and (max-width: 575px) {
    .modal-bill-amount-wrap {
        display: block !important;
        margin: 32px 0px;
    }

    .bill-amount-box-inner {
        max-width: 100%;
        margin: 0 0 23px;
    }
}