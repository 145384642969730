.wirard-active-body .support-button, .wirard-active-body .support-button:hover{
    z-index: 2;
   }
   .wirard-active-body .grecaptcha-badge {
       z-index: 2 !important;
   }
   .wizard-wrapper:after {
       position: fixed;
       content: "";
       background: rgba(0, 0, 0, 0.7);
       height: 100%;
       width: 100%;
       left: 0;
       top: 0;
       z-index: 9;
   }
   
   .wizard-data-holder {
       position: absolute !important;
       left: 0;
       right: 0;
       top: 0px;
       max-height: 100%;
       padding-top: 0px !important;
       padding-bottom: 0px !important;
       margin-top: 0px !important;
       margin-bottom: 0px !important;
       min-height: 100% !important;
      
   }
   
   .wizard-container {
       border-radius: 20px;
       background: #FFF;
       box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.50);
       width: 335px;
       position: absolute;
       z-index: 99;
       left: 450px;
       padding: 20px;
       top: 24px;
       transition: 0.3s;
   }
   
   .wizard-slide-2 .wizard-container {
       top: 110px;
       left: 413px;
   }
   
   .wizard-slide-3 .wizard-container {
       top: 270px;
       left: 413px;
   }
   
   .wizard-slide-4 .wizard-container {
       top: 26px;
       left: auto;
       right: 184px;
   }
   
   
   .progress-bar-container {
       height: 2px;
   }
   
   .progress-bar-inner {
       height: 2px;
       transition: 0.3s;
   }
   
   .skipp-all {
       padding: 10px 20px;
   }
   
   .slide-count-text .gray-color {
       color: #ccc;
   }
   
   .wizard-image-area {
       background: #F5F5F5;
       text-align: center;
       border-radius: 15px;
       height: 300px;
       display: flex;
       align-items: center;
       justify-content: center;
   }
   .wizard-image-area img{
       max-height: 100%;
   }
   .wizard-items-repeat .subtitle-2{
    line-height: 26px;
   }
   .wizard-button-wrap .btn-disabled{
       visibility: hidden;
   }
   
body.wirard-active-body.modal-open .modal-backdrop.show{
    opacity: 0.8;
}

   @media screen and (max-width:1999px) and (min-width:1600.99px) {
       .wizard-container{
           left: 470px;
       } 
       .wizard-slide-2 .wizard-container,  .wizard-slide-3 .wizard-container{
           left: 432px;
       }
       .wizard-slide-4 .wizard-container{
           right: 204px;
       }
   }
   
   @media screen and (max-width:1199px) {
       .wizard-container{
           left: 430px;
       }
   
       .wizard-slide-2 .wizard-container {
           top: 110px;
           left: 398px;
       }
       .wizard-slide-3 .wizard-container{
           left: 398px;  
       }
   }
   
   @media screen and (max-width:900px) {
       .wizard-container {
           left: 342px;
       }
   
       .wizard-slide-2 .wizard-container,
       .wizard-slide-3 .wizard-container {
           left: 378px;
       }
   
       .wizard-slide-4 .wizard-container {
           right: 188px;
       }
   }
   
   @media screen and (max-width:767px) {
       .wizard-wrapper .wizard-container {
           left: auto !important;
           right: auto !important;
           margin: 0 auto !important;
           position: absolute;
           top: 13px;
           margin-bottom: 10px !important;
       }
   
       .wizard-wrapper:after{
           display: none;
       }
       .wizard-data-holder {
           position: fixed !important;
           left: 0;
           right: 0;
           top: 0px;
           max-height: 100%;
           padding-top: 0px !important;
           padding-bottom: 0px !important;
           margin-top: 0px !important;
           margin-bottom: 0px !important;
           min-height: 100% !important;
           overflow-y: auto;
           background: rgba(0, 0, 0, 0.7);
           z-index: 999;
           display: flex;
           align-items: center;
           justify-content: center;
       }
   
       .wizard-items-repeat .subtitle-2 {
           line-height: 24px; 
           font-size: 16px;
           font-weight: 500;
       }
       .wizard-m-open{
         overflow: hidden !important;
       }
   }