.project-modal-img{
    background-color: unset !important;
}
.change-welcome-image {
    margin-top: 12px;
}

.upload-project-img img, .uploaded-project-img img {
    width: 319px;
    height: 446px;
    object-fit: contain;
    border-radius: 20px;
}

.intro-image-toggle-wrap .toggle-btn-wrap{
    display: table;
    margin: 0 auto;
}
.intro-image-toggle-wrap .toggle-btn-wrap .slider{
    left: auto;
    right: 0px;
}
.intro-image-toggle-wrap .toggle-with-label .toggle_btn_label{
  padding-left: 0px;
  padding-right: 54px;  
}
.intro-image-wrap {
    width: 319px;
    height: 446px;
    display: flex;
    align-items: center;
    background-color:#F5F5F5;
    border-radius: 20px;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin:0  auto;
}

.unset-intro-img {
    max-width: 221px;
    text-align: center;
    color: #000;
}
.unset-intro-img img {
    width: 96px;
    height: 96px;
    margin-bottom: 15px;
}
.left-preview-img{
    display: flex;
    align-items: center;
}
.left-preview-img img{
    border-radius: 8px;
    height:auto;
}
.welcome-test-preview-area-inner .caption {
    color: var(--gray-700);
    line-height: 20.25px;
    font-size: 11.34px;
}
.four-line{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:4;
}
.one-line{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:1;
}
.test-preview-container .body-text-2.one-line{
    font-size: 16.2px;
    line-height: 26.73px;
}
.test-preview-container .primary-medium{
   min-height: 34px;
   padding: 6.48px 16.2px;
   font-size: 11.34px;
    line-height: 20.25px;
}