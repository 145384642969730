.no-access-page-hold {
    margin: 38px auto !important;
}

.no-access-page-hold .workspace-page-loader{
    min-height: calc(100vh - 218px);
}

.no-access-page-wrap {
    max-width: 957px;
    margin: 80px auto 0;
    padding: 15px;
    text-align: center;
}

.no-access-page-wrap .tester-disbled-account-notice svg {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
}

.no-access-page-wrap .tester-disbled-account-notice {
    border-radius: 10px;
}

.access-available-workspaces {
    max-width: 800px;
    margin: 60px auto 32px;
    text-align: left;
}

.access-available-workspaces-left {
    padding-top: 18px;
}

.access-available-workspaces .workspace-list-repeat {
    width: 323px;
    border-bottom: 1px solid #E9E9E9;
}

.access-available-workspaces .added-workspaces-for-user {
    border-bottom: 0;
    padding: 0;
}

.access-available-workspaces .added-workspaces-for-user a:last-child .workspace-list-repeat {
    border-bottom: 0px;
}

.access-available-workspaces .workspace-arrow {
    position: relative;
    right: -11px;
}

.access-available-workspaces .span-workspace-owner-icon {
    background-color: #000;
}

.access-available-workspaces .added-workspaces-for-user .workspace-type-info p {
    width: 240px;
}

.access-available-workspaces .workspace-type-info {
    margin: 20px 0px;
}

.access-available-workspaces .workspace-type-info .caption {
    display: block;
    color: #9C9C9C;
}

.access-available-workspaces-left h3 {
    margin-bottom: 4px;
}

@media screen and (max-width:600px) {
    .access-available-workspaces {
        display: block !important;
    }

    .access-available-workspaces-right {
        margin-top: 32px;
    }

    .access-available-workspaces .workspace-list-repeat {
        width: 295px;
    }

    .access-available-workspaces .added-workspaces-for-user .workspace-type-info p {
        width: 200px;
    }
}