.image-library-modal .modal-dialog{
    max-width: 1024px; 
}
.image-library-modal .modal-header-top{
    margin-bottom: 12px;
}
.image-gallery {
    display: flex;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 0fr);
    flex-wrap: wrap;
   
}
.image_lib_hold img {
    max-width: 100%;
    height: 100%;
    display: table;
    margin: auto;
    object-fit: contain;
}
.image_lib_hold {
    width: 230px;
    height: 230px;
    padding: 10px 10px;
    border: 5px solid #E9E9E9;
    transition: 0.3s;
    cursor: pointer;
    position: relative;
}

.image_lib_hold:hover{
    border-color: #000;
}
.image_lib_hold.active{
    border-color: #000;
}

.img-lib-scroll-wrap{
    /* max-height: 473px; */
     /* max-height: 340px; */
    
    overflow-y: auto;
    height: calc(100vh - 410px);
}
.lib-modal-button-wrap button{
    margin: 0px 20px;
}
.no-uploaded-library-data{
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(100vh - 410px);
    align-items: center;
}
.no-uploaded-library-data .no-img-content{
max-width: 470px;
}
.delete-img{
 position: absolute;
 width: 24px;
 height: 24px;
 border-radius: 2px;
 background-color: #F0F0F0;
 top: 10px;
 right: 10px;
 display: flex;
 align-items: center;
 justify-content: center;
}

@media screen and (max-width:1035px){
   .image-gallery{
    justify-content: center;
   }
}


@media screen and (max-width:767px){
    .image-filter-wrap .filter-button-option {
      display: none;
    }
    .image-filter-wrap .filter-button-option:first-child, .image-filter-wrap .filter-button-option:last-child {
        display: inline-flex;
        width: 50%;
        text-align: center;
        justify-content: center;
      }
      .lib-modal-button-wrap button {
        margin: 0px 6px;
    }
}


@media screen and (max-width:600px){
    .image_lib_hold{
        width: 45%;
        height: 180px;
    }
    .filter-buttons-wrap{
        margin-top: 0px;
    } 
    .image-filter-wrap{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    /* .img-lib-scroll-wrap{
        max-height: 280px;
    } */
    .lib-modal-button-wrap button{
        margin: 10px 0 0;
        width: 46%;
        font-size: 12px;
        padding: 7px 15px;;
    }
    .lib-modal-button-wrap button.secondary-large svg{
        width: 18px;
        height: 18px;
    }
    .lib-modal-button-wrap.justify-content-center{
        justify-content: space-between !important;
        margin-top: 15px;
    }
}


.fetch-loader{
    display: flex;
    justify-content: center;
    align-items: center;
}
.fetch-loader .loader_section img{
    width: 70px;
}
.image-library-modal .modal-dialog{
    margin-top:40px ;
    margin-bottom: 40px;
}
.image-library-modal .modal-body {
    height: calc(100vh - 80px);
}
.image-library-modal .fetch-loader{
    height: calc(100vh - 410px);
    display: flex;
    justify-content: center;
    align-items: center;
}