.setting-switch-member-data .switch-mamber-team {
    position: static !important;
    padding: 28px;
    background-image: url('/public/img/I-W-S.png');
    color: #fff;
    border-radius: 15px;
    position: absolute;
    bottom: 32px;
    background-size: cover;
    justify-content: space-between; 
    background-position: bottom;
    width: 100%;
}