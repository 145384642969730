.taking-test-question-image.take-test-question-image-wrap img {
    max-width: 100%;
    width: auto;
}
.taking-test-question-image.take-test-question-image-wrap {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
}

.answer-option-image-wrap {
    border-radius: 10px;
    border: 1px solid #E9E9E9;
    background: #fff;
    padding:0;
    width: 220px;
    height: 220px;
}


.answer-option-image-wrap img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.max-icon-hold {
    display:none;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.5);
    left: 0;
    top: 0;
    border-radius:10px;
}

.answer-option-image-wrap:hover .max-icon-hold{
 display: flex;
} 