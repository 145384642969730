.ai_loader_img{
    width: 88px;
}
.AI_loader_icon  .animation-svg-hold {
    width: 100% !important;
    height: 150px !important;
}

.AI_loader_icon .lottie-hold {
    position: relative;
    height: auto;
    top: 0;
}