.project-card-hold {
    padding: 10px;
}
.project-card-hold a{ 
 color: #000; 
 text-decoration: none;
}

.card-top-link, .card-top-link:hover{
    color: #000; 
    text-decoration: none;
}
.project-card-wrap {
    background: #F5f5f5;
    width: 344px;
    min-height: 233px; 
    border-radius: var(--xl);
    position: relative;
    border:1px solid #F5f5f5;
    transition: 0.3s;
}
.project-card-wrap:hover{
    background-color: #fff;
    border-color: #ccc;
} 
.project-card-loading:hover{
    background: #F5f5f5;
    border:1px solid #F5f5f5; 
}

.project-creaded-data .circular-name-icon{
    margin-right: 8px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    font-size: 14px;
    position: static;
}
.p-card-footer{
    position: static;
    bottom: 20px;
    width: 100%;
    left: 0;
    padding-top: 67px;
}

.p-card-top-hold a{
   text-decoration: none;
}
.menu-item svg {
    width: 16px;
    height: 16px;
}
.menu-item svg circle{
   fill: #676767;
}

.menu-item:hover svg circle{
    fill: #000;
 }

.project-card-head-left{
    width: calc(100% - 60px);
}
.project-card-head-left .card-top-link{
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    color: #000;
}

.project-card-data .dropdown-menu.show {
    display: block;
    right: 15px !important;
    top: -1px !important;
} 
.p-card-footer .caption {
    display: block;
    margin-bottom: 8px;
}
.project-card-wrap .circular-name-icon:hover .tooltip-wrapper{
    min-width: 97px; 
    max-width: 100%;
}
.p-card-right-footer .tooltip-wrapper{
    white-space: nowrap !important;
    max-width: fit-content !important;
}
.p-card-right-footer .circular-name-icon-wrap span{
    border: 1.6px solid #f5f5f5;
    width: 30px;
    height: 30px;
    min-width: 30px;
    font-size: 15px;
}

.skelton-circular-load{
    justify-content: end !important;
}
.skelton-circular-load span{
    min-width: auto !important;
    margin:0px 0px 0px -5px;
    margin-left: -10px !important;
}
.skelton-circular-load span:first-child{
    margin-left: 0px !important;
}

.p-card-lft-footer h1 {
    line-height: 30px; 
}
.p-card-right-footer .circular-name-icon-wrap {
    position: relative;
    top: 2px;
}

.project-card-top-data .card-top-link .circular-name-icon .tooltip-wrapper{
    left: -10px;
        right: auto;
        top:103px;
}
.project-card-top-data .card-top-link .circular-name-icon .tooltip-wrapper.top-tooltip::before{
    left: 40px;
}
.project-card-top-data .card-top-link .small-tooltip .circular-name-icon .tooltip-wrapper{
    left:auto;
    white-space:nowrap;
}
.project-card-top-data .card-top-link .small-tooltip .circular-name-icon .tooltip-wrapper.top-tooltip::before{
    left: 50%;
}


.test-tooltip-hold{
    width:15px;
    height: 15px;
    display: inline-block;
}
.project-card-wrap .top-tooltip::before{
 left: 50% !important;
}
.project-creaded-data.big-tooltip .top-tooltip:before {
    left: 40px !important;
}

.live-test-indi {
    width: 8px;
    margin-top: -3px;
    height: 8px;
    background: #378B10;
    border-radius: 100%;
    display: inline-block;
    margin-left: 5px;
    animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
    position: relative;
    top: -1.3px;
}
.test-tooltip-hold .tooltip-wrapper{
    min-width: auto;
    width: auto;
    color: #BFF0A8 !important;
    margin: 0px;
    left: -52px;
    right: auto;
    white-space: nowrap;
    bottom: 27px;
}
.p-card-right-footer .tooltip-wrapper{
    top: 41px;
}
.p-card-footer .p-card-lft-footer .test-tooltip-hold .bottom-tooltip::before{
left: 61px;
} 


  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; } 
  }

  .loading-cards .all-test{
    height:171px;   
}

@media screen and (max-width: 1366px) {
    .project-card-top-data .card-top-link .circular-name-icon .tooltip-wrapper{
        left: -20px;
            right: auto;
            top: 104px;
    }
    .project-card-top-data .card-top-link .circular-name-icon .tooltip-wrapper.top-tooltip::before{
        left: 53px;
    }
}


@media screen and (max-width:767px) {
    .create-pro-btn .create-test-btn {
        margin-left: 10px;
    }
}