.box-signup-card {
    border-radius: 20px;
    border: 1px solid var(--light-dark-400, #E9E9E9);
    background: var(--light-dark-100, #FCFCFC);
    padding: 32px;
}
.box-signup-card .owl-dots{
display: none;
}
.sign-up-cards svg{
    width: 40px; 
    height: 40px;
}
.sign-up-cards .owl-nav button.disabled {
    opacity: 0.2;
    cursor: not-allowed;
    display: inline-flex !important;
}

