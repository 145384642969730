/***Add member modal css***/
.add-member-modal .modal-dialog{
    max-width: 600px;
}
.add-member-modal .workspace-email-wrap .form-group, .add-member-modal .create-workspace-name .form-group{
    max-width: 100%;
    width: 100%; 
}
.add-member-modal .workspace-email-wrap, .add-member-modal .create-workspace-name {
    width: 100%;
}
.add-member-modal .seats-available-count.d-flex.align-items-center {
    position: static;
}
.add-member-modal .toast-message-wrap{
    margin-top: 32px;
}
.add-member-modal .toast-message-wrap .toast-data a{
    color: #9759CB;
}
.add-member-modal .toast-message-wrap .toast-data a:hover{
 color: #5C208D;
}
.add-member-modal-btn {
    margin: 32px -16px 0;
}
.add-member-modal-btn button{
    margin:0px 16px;
}

@media screen and (max-width:400px){ 
    .add-member-modal-btn{ 
      display: block !important;
      margin:32px 0px 0px;
    }
    .add-member-modal-btn button{
        width: 100%;
        margin:10px 0px 0px;
    }
}