.screen-byuser-filter-wrap {
    width: calc(100% - 130px);
}
#screening_outcome {
    width: 189px;
    margin-right: 26px;
}
.screening-buy-user-data-warap .search-input-field {
    width: 400px;
}

.screen-question-th .info-wrap{
    margin-left: 8px;
    display: inline-block;
}
.screen-user-table  tr th{
    white-space: nowrap;
}
.screen-user-table tr th .tooltip-wrapper{
    white-space: break-spaces;
}
.screen-question-th{
    min-width: 107px;
}
.screen-user-table tr td{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.screen-user-table .react-loading-skeleton{
    width: 100% !important;
}
.screen-user-table .info-wrap{
    z-index: 2;
}

@media screen and (max-width:991px) {
    .screen-by-user-top-head {
        display: block !important;
    }
    .screen-byuser-filter-wrap {
        width: 100%;
        margin-top: 24px;
        display: block !important;
    }
    #screening_outcome {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px !important;
    }
    .screen-by-user-top-head .form-group {
        max-width: 100%;
        width: 100% !important;
        margin-bottom: 24px !important;
    }
    
}


