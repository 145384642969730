.chip-member, .chip-admin{
    padding: 6px 8px;
    border-radius: 50px;
    background:#EFDCFF;
    justify-content: space-between;
    border: 0px;
    color:var(--gray-900);
    }
    .chip-admin{
        padding: 6px 12px 6px 8px;
        justify-content: start;
    }
    
    .chip-member .circular-name-icon, .chip-admin .circular-name-icon{
        width: 24px;
        min-width: 24px;
        height: 24px;
        text-transform:uppercase;
        font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.75px; 
    margin-right: 8px;
    }
    
    .chip-member .chip-option-icon-right {
        background: var(--gray-900);
        
        width: 24px;
        min-width: 24px;
        height: 24px;
        display: flex;
        border-radius: 100%;
        text-align: center;
        padding: 2px 0;
        align-items: center;
        margin-left: 6px;
        justify-content: center;
    }