
/***Multiselectlist css***/
.searchdropdown-box-container {
    border: 1px solid #9C9C9C;
    border-radius: 10px;
    padding: 13px 20px; 
    cursor: pointer;
    width: 214px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    height: 55px;
}
.searchdropdown-selected-values-container:empty {
    display: none;
}
.searchdropdown-box-container .body-text{
    color: #9C9C9C;
    position: absolute;
    transition: 0.2s all;
}

.searchdropdown-dropdown-icon-container {
    justify-content: end;
    display: flex;
    position: absolute;
    right: 20px;
    background-color: #FFF;
}

.searchdropdown-box-container.focussed .body-text{
    position: absolute;
    top: -9px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    padding: 0px 8px;
    background: #fff;
    color: #676767;
    left: 13px;
}

.searchdropdown-options {
    border-radius: 10px;
    border: 1px  #E9E9E9;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    z-index:2;
    width: 100% !important;
    padding: 20px;
    top:65px;
    max-height: 300px;
    overflow-y: auto;
}

.searchdropdown-box .number-icon, 
.searchdropdown-box .star-icon, 
.searchdropdown-box .emoticon-icon{
    border: 0px;
    border-radius: 0px;
    padding: 0;
    cursor: pointer;
    margin: 0;
    height:auto;
    width: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: #000;
    box-shadow: none !important;
}
.searchdropdown-box .number-icon img, 
.searchdropdown-box .star-icon img, 
.searchdropdown-box .emoticon-icon img{
    width: 24px;
    height: 24px;
}

.searchdropdown-box{
    width: 214px;
}
.searchdropdown-option {
    margin-bottom: 20px;
}
.searchdropdown-option:last-child{
    margin-bottom: 0px;
} 

.searchdropdown-option label {
    margin: 0;
    padding-left: 38px;
}
.searchdropdown-selected-values-container {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    display:flex;
    align-items:center;
}
.searchdropdown-option .star-icon img {
    margin-left: 2px;
}
.searchdropdown-option .star-icon img:first-child{
    margin-left: 0px;
} 
.searchdropdown-selected-values-container img {
    margin-right: 1px;
}
.searchdropdown-selected-values-container .rating-scale-icon:nth-child(4) {
    margin-right: 4px;
}
.searchdropdown-box-container.focussed, .searchdropdown-box-container:hover{
    border-color: #676767;
}
.searchdropdown-option .emoticon-icon {
    position: relative;
    top: -1.5px;
}
.big-multi-select .searchdropdown-box-container {width: 100%;
}
.big-multi-select {
    width: 100%;
    max-width: 
400px
;
}

.searchdropdown-selected-values-container input{
 width: 87%;
 border: 0px;
 outline: none;
 background: none;
 position: relative;
 z-index: 1;
}