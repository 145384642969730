/***Multiselectlist css***/
.multiselectlist-dropdown-box-container {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 13px 20px;
    cursor: pointer;
    width: 214px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
}
.multiselectlist-dropdown-selected-values-container:empty {
    display: none;
}
.multiselectlist-dropdown-box-container .body-text {
    color: #9c9c9c;
    position: absolute;
    transition: 0.2s all;
}

.multiselectlist-dropdown-dropdown-icon-container {
    justify-content: end;
    display: flex;
    position: absolute;
    right: 8px;
}

.multiselectlist-dropdown-box-container.focussed .body-text {
    position: absolute;
    top: -9px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    padding: 0px 8px;
    background: #fff;
    color: #676767;
    left: 13px;
}

.members-multiselect-dropdown .multiselectlist-dropdown-options {
    border-radius: 10px;
    border: 1px #e9e9e9;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    z-index: 2;
    width: 100% !important;
    padding: 0;
    top: 45px;
    max-height: 300px;
    overflow-y: auto;
}

.members-multiselect-dropdown
    .multiselectlist-dropdown-option.disabled-option
    .member-label {
    opacity: 0.3;
    cursor: not-allowed;
}

.members-multiselect-dropdown
    .multiselectlist-dropdown-option.disabled-option
    .added-member {
    display: flex !important;
}

.multiselectlist-dropdown-box .number-icon,
.multiselectlist-dropdown-box .star-icon,
.multiselectlist-dropdown-box .emoticon-icon {
    border: 0px;
    border-radius: 0px;
    padding: 0;
    cursor: pointer;
    margin: 0;
    height: auto;
    width: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: #000;
    box-shadow: none !important;
}
.multiselectlist-dropdown-box .number-icon img,
.multiselectlist-dropdown-box .star-icon img,
.multiselectlist-dropdown-box .emoticon-icon img {
    width: 24px;
    height: 24px;
}

.multiselectlist-dropdown-box {
    width: 214px;
}
.members-multiselect-dropdown .multiselectlist-dropdown-option {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 16px 20px;
    justify-content: space-between;
}
.members-multiselect-dropdown
    .multiselectlist-dropdown-option
    .circular-name-icon {
    margin-right: 12px;
}

.members-multiselect-dropdown .multiselectlist-dropdown-option:last-child {
    margin-bottom: 0px;
}

.multiselectlist-dropdown-option label {
    margin: 0;
    padding-left: 38px;
}
.multiselectlist-dropdown-selected-values-container {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.multiselectlist-dropdown-option .star-icon img {
    margin-left: 2px;
}
.multiselectlist-dropdown-option .star-icon img:first-child {
    margin-left: 0px;
}
.multiselectlist-dropdown-selected-values-container img {
    margin-right: 1px;
}
.multiselectlist-dropdown-selected-values-container
    .rating-scale-icon:nth-child(4) {
    margin-right: 4px;
}
.multiselectlist-dropdown-box-container.focussed,
.multiselectlist-dropdown-box-container:hover {
    border-color: #676767;
}
.multiselectlist-dropdown-option .emoticon-icon {
    position: relative;
    top: -1.5px;
}
.big-multi-select .multiselectlist-dropdown-box-container {
    width: 100%;
}
.big-multi-select {
    width: 100%;
    max-width: 400px;
}

.multiselectlist-dropdown-box-container .select-member-dropdown .body-text {
    position: static;
}

.select-member-dropdown span {
    display: inline-block;
    margin-right: 8px;
}

.members-multiselect-dropdown
    .multiselectlist-dropdown-selected-values-container
    .chip-name {
    display: flex;
    align-items: center;
}
.members-multiselect-dropdown
    .multiselectlist-dropdown-selected-values-container
    .chip-name
    .circular-name-icon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24.75px;
}

.members-multiselect-dropdown
    .multiselectlist-dropdown-selected-values-container
    .chip.chip-default {
    padding: 6px 12px 6px 8px;
    background: #efdcff;
    border: 0px;
    color: var(--gray-900);
}

.members-multiselect-dropdown
    .multiselectlist-dropdown-selected-values-container
    .chip {
    margin: 8px 8px 0 0px;
    padding: 6px 8px 6px 16px;
}
.multiselectlist-dropdown-options:empty {
    display: none;
}

.added-member svg {
    margin-right: 8px;
}

.multiselectlist-dropdown-option:hover {
    background-color: #f5f5f5;
}

.multiselectlist-dropdown-box.members-multiselect-dropdown {
    min-width: 275px;
}

.members-multiselect-dropdown .multiselectlist-dropdown-box-container {
    width: 100%;
}
.select-member-dropdown input {
    border: 0px;
    outline: unset;
    box-shadow: unset;
    min-width: 305px;
}
.multiselectlist-dropdown-readonly {
    pointer-events: none !important;
}
.multiselectlist-dropdown-readonly .multiselectlist-dropdown-box-container {
    border: 1px solid var(--gray-500) !important;
}
.multiselectlist-dropdown-readonly .select-member-dropdown input {
    color: var(--gray-500) !important;
}
.multiselectlist-dropdown-readonly .select-member-dropdown input::placeholder {
    color: var(--gray-500) !important;
}
