.info-text .info-wrap {
    margin-left: 8px;
}

.info-text .info-wrap .tooltip-wrapper {
    margin: 0;
    left: -70px;
    width: 242px;
    z-index: 333;
    text-align: center;
}

.info-text .info-wrap .bottom-tooltip::before {
    left: 34%;
}

.saved-payment-link a {
    font-size: 14px;
}

.table-wrapper {
    max-width: 100%;
}

.workspace-billing-section {
    min-height: 468px;
    position: relative;
}

.downgrade-link {
    position: absolute;
    bottom: 32px;
}

.downgrade-link a {
    font-size: 12px;
    line-height: 17px;
}

.no-access-inner svg {
    display: table;
    margin: 0 auto;
    width: 40px;
    height: 40px;
}

.no-access-box {
    min-height: 282px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.workspace-billing-info-wrap {
    max-width: 431px;
}

.workspace-page-loader {
    min-height: calc(100vh - 270px);
}

.switch-mamber-team {
    padding: 20px;
    background-image: url("/public/img/book-demo-bg.png");
    color: #fff;
    border-radius: 15px;
    position: absolute;
    bottom: 32px;
    background-size: cover;
    justify-content: space-between;
    width: calc(100% - 60px);
}

.switch-mamber-team .subtitle-2 {
    color: #fff;
    line-height: 26px;
    padding-right: 15px;
}

.white-bg-btn:hover {
    color: #000 !important;
    background-color: #fff !important;
    border-color: #fff !important;
}

.work-space-billing-info-repat .caption.gray-color {
    display: block;
    margin-bottom: 8px;
}

.workspace-billing-section .nocard-data {
    margin-bottom: 12px;
}

.selected-payment-card .payments-cards.d-flex {
    max-width: 320px;
    margin-right: 20px;
}

.billing-info-heading {
    margin-bottom: 12px;
}

.workspace-billing-info-wrap
    .work-space-billing-info-repat
    .caption.gray-color {
    margin-bottom: 4px;
}

.billing-info-withbtn button {
    margin-top: -15px;
}

.workspace-billing-info-wrap p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.work-space-billing-info-repat p {
    font-size: 14px;
    line-height: 25px;
}

.card-right-side .tag {
    margin-right: 5px;
}

@media screen and (max-width: 1280px) {
    .switch-mamber-team .subtitle-2 {
        color: #fff;
        line-height: 25px;
        padding-right: 10px;
        font-size: 16px;
    }

    .white-bg-btn {
        font-size: 16px;
    }
    .change-payment-skel .big-skel {
        width: 181px !important;
    }
}

@media screen and (max-width: 1199px) {
    .switch-mamber-team {
        display: block !important;
        width: 100%;
        position: static;
        background-position: bottom;
    }

    .switch-mamber-team .subtitle-2 {
        font-size: 20px;
        display: block !important;
        padding-left: 0;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 991px) {
    .workspace-billing-section .row .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 100%;
        max-width: 100%;
    }

    .work-space-billing-info-repat {
        margin-bottom: 20px;
    }

    .workspace-billing-section .row.mb-40 {
        margin-bottom: 0;
    }

    .switch-mamber-team .subtitle-2 {
        font-size: 16px;
        line-height: 23px;
    }

    .switch-mamber-team .white-bg-btn {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 767px) {
    .work-space-billing-info-repat {
        margin-bottom: 20px;
    }

    .workspace-billing-section {
        margin-bottom: 32px;
        min-height: auto;
    }

    .workspace-billing-section .row.mb-40 {
        margin-bottom: 0;
    }

    .downgrade-link {
        position: static;
    }

    .workspace-billing-wrapper
        .row
        .col-md-6:nth-child(2)
        .workspace-billing-section {
        margin-bottom: 0px;
    }
    .change-payment-skel .big-skel {
        width: 121px !important;
    }
}
