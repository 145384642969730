.project-card-hold{
    width: 25%;
    padding: 10px;
}
.project-card-hold .project-card-wrap{
    width: 100%;
}
.create-pro-btn .create-test-btn{
 margin-left: 20px;
}

@media screen and (max-width:1279px){
    .project-card-hold {
        width: 33.333%;
    }
    
}

@media screen and (max-width:991px){
    .project-card-hold {
        width: 50%;
    } 
}


@media screen and (max-width:699px){
    .project-card-hold {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    } 
}