.lottie-hold{
    position: relative;
    height:70px;
    top: -28px;
} 
.animation-svg-hold {
    width: 100% !important;
    height: 520px !important;
}

.lottie-hold svg {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto;
}
.test-live-successfully-modal .modal-body{
 padding: 28px;
}
.test-live-successfully-modal .congrats-modal-text{
    padding: 0px;
}
.new-workspace-active-modal .congrats-modal-text, .tester-congrats-modal .congrats-modal-text{
    padding: 0px;
    margin-top:0;
}

.new-workspace-active-modal .modal-body, .tester-congrats-modal .modal-body{
    padding: 28px;
}


.new-workspace-active-modal .lottie-hold, .lottie-position-absolute{
    top: 0px;
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
}

.new-workspace-active-modal .animation-svg-hold, .lottie-position-absolute .animation-svg-hold{
    position: absolute;
    height: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.new-workspace-active-modal .lottie-hold  svg, .lottie-position-absolute svg{
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto;
}

