.ai-creator-top {
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 22px;
  
}
.ai-search-wrap {
    margin: 18px 0px;
}
.ai-creator-top-left .tag {
    margin: 0px 0 0 18px;
}
.ai-topic-label {
    padding: 10px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #E9E9E9;
    color: #676767;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    margin: 5px;
}
.ai-topic-list {
    margin: -5px;
}
.mt-18 {
    margin-top: 18px;
}
.add-ai-logic-form-wrap .form-group{
    max-width: 100%;
}
.add-ai-logic-form-wrap .textarea-form-group .form-control{
 min-height: 240px;
}
.generate-ai-btn {
    margin: 100px 0;
}
.generate-ai-btn .btn{
    min-width: 260px;
}
.device-selection-radio.d-flex label.radiobox{
    flex-direction: column;
   
    padding: 24px 12px ;
    text-align: center;
    transition: all 200ms ease-in;
}
.radiobox:hover:not(.active){
    background-color: #FCFCFC !important;
    border-color: #ccc !important;
}

.radiobox .radio-img{
    margin-right: 0px;
}
.device-selection-radio.d-flex label.radiobox .body-text-1{
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-top: 8px;
}
.device-selection-radio.d-flex label.radiobox .tag.black-tag {
    margin-bottom: 8px;
    margin-top: 4px;
}
@media screen and (min-width:767px){
.device-selection-radio.d-flex label.radiobox{
   
    max-width: calc(33.3% - 24px);}
}             

.generate-with-AI-accordian .accordian-header h3 {
    width: calc(100% - 95px);
    justify-content: space-between;
}


.generate-with-AI-accordian .accordian-header-subtitle {
    display: inline-flex;
    align-items: center;
    /* new css */
    margin-left: auto;
    
}
.generate-with-AI-accordian .accordian-header-subtitle .ghost-btn{
 margin-right: var(--s);
}
.ai-radio-wrap .radio-btn-wrap{
 margin-bottom: 16px;
}
.ai-radio-wrap .radio-btn-wrap:last-child{
    margin-bottom: 0px;
}
.navy-color path{
    stroke: #2151F5;
}
.ai-icon-left{
    margin-left: 10px;
    margin-right: 10px;
}
.ai-icon-left  .tooltip-icon .tooltip-wrapper{
    max-width: 160px;
    left: -121px;
}
.tooltip-icon .tooltip-wrapper{
    text-align: center;
    min-width: 100px;
    left: -143px;
    /* right: -4px; */
    bottom: 40px;
    max-width: 218px;
}
.tooltip-icon:hover .tooltip-wrapper{
    display: block;
}

.modal-btn.ai-modal-btns .btn {
    /*margin-left: 20px;*/
}

.questiondescription-s.d-flex.question-added-view-wrap.arabic_wrapper{
    padding-right: 23px;
}

@media screen and (min-width:767px){
.device-selection-radio.d-flex label.radiobox{
    max-width: calc(33.3% - 24px);
    margin-bottom: 0px;;
}        
}     
              
@media screen and (max-width:767px) {


    .added-question-readtmode-top-wrap{
        position: relative;
      }
      .ai_icon_added.d-flex.align-item-center {
          position: absolute;
          right: 0;
          top: 36px; 
          z-index: 2;
      }
      .ai-icon-left{
          margin-right: 0px !important;
          margin-left: 0px !important;
      }
     
      .tooltip-icon.position-relative {
          margin-left: 10px;
      }
      .ai-icon-left .tooltip-icon{
          margin-left: 0px;
      }

      
    .ai-creator-top-left {
        width: calc(100% - 60px);
        justify-content: space-between;
    }
    .ai-search-left p {
        font-size: 14px;
        line-height: 20px;
        padding-right: 10px;
    }
}
@media screen and (max-width:480px) {
    .device-selection-radio.d-flex label.radiobox{
   
   width: 100%;
} 
.ai-btn{
    padding: 8px 10px;
    font-size: 12px;
} 
.ai-btn .inline-svg-medium svg{
    width: 18px !important;
    height: 18px !important;
}
.generate-with-AI-accordian .accordian-header h3 {
    width: calc(100% - 75px);
    justify-content: space-between;
}
.justify-content-between.duplicate-test-modal-action{
    flex-direction: column;
}
.justify-content-between.duplicate-test-modal-action .btn.primary-large{
    margin: 10px 0px;
}
}
.mb-32{
    margin-bottom: 32px;
}
.ai-icon{
    z-index: 99;
}