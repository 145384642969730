/*body{margin-top:20px;
    background:#eee;
    }
  */  
  .invoice {
    padding: 40px;
    font-family: 'Inter', sans-serif;
}

.invoice h2 {
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px; 

}

.receipt-left {
    font-size: 11px;
    width: 111px;
}
.receipt-repeat {
    margin: 2px 0;
}

.mid-invoice{
    padding-top: 20px;
    padding-bottom: 20px;
}

.bill-heading-pdf{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    color: #000;
    margin-bottom: 8px;
}

.billed-info-pdf-repeat {
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
}
.invoice .small {
    font-weight: 300;
}
.pdf-table-wrap{
    margin-top: 30px;
}
.pdf-table-wrap b{
    font-weight: 600 !important;
}

.invoice hr {
    margin-top: 10px;
    border-color: #ddd;
}

.invoice .table tr.line {
    border-bottom: 1px solid #BEBEBE;
}

.invoice .table td {
border: none;
padding: 12px 0;
font-size: 12px;
}
.invoice .table tr:hover{
background-color: unset !important;
}
.invoice .table{
width: 100%;
}

.pdf-footer-row td {
border-top: 1px solid #BEBEBE !important;
font-size: 9px !important;
color: #878787 !important;
}

.pdf-amount-row td{
font-size: 14px !important;
}
.pdf-amount-row .border-td {
border-top: 1px solid #BEBEBE !important;
}

.invoice .identity {
    margin-top: 10px;
    font-size: 1.1em;
    font-weight: 300;
}

.invoice .identity strong {
    font-weight: 600;
}


.grid {
    position: relative;
    width: 100%;
    background: #fff;
    color: #666666;
    border-radius: 2px;
    margin-bottom: 25px;
  
}
.table-ptf-first-row td{
    padding-bottom: 40px !important;
}


.pdf-bottom-row td {
border-top: 1px solid #BEBEBE !important;
font-size: 16px !important;
padding: 20px 0px !important;
font-weight: 500 !important;
}
.pdf-due-amount-row td{
padding-bottom: 40px !important;
}


.pdf-bottom-row td {
border-top: 1px solid #BEBEBE !important;
padding-bottom: 250px !important;
}
