.tester-page-wrapper.card-sorting-take-test-wrap {
    padding: 0;
}
.card-sorting-test-view-data-wrap{
    margin: 0px;
}
.card-sorting-take-test-wrap .container{
    max-width: 100%;
    padding: 0px 0px 0px 0px;
}
.card-sorting-tests-header-wrap {
    background: #FCFCFC;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 40px;
}
.card-sorting-cat-view-wrap{
 padding-top: 0px;
 padding-right: 40px;
}
.card-sorting-view-wrap{
    min-height:100%; 
    max-height: calc(100vh - 206px);
    border-color: #E9E9E9;
    border-left: 0px;
    border-top:0px;
    background-color: #fff;
    border-radius: 0px;
    padding: 20px 12px;
}

.card-sorting-selected-test-cat {
    min-height: calc(100vh - 380px);
    overflow-y: auto;
    max-height:unset;
}
.cardtake-test-top{
    position: sticky;
    background: #fff;
    z-index: 333;
    top: 80px;
    padding: 20px 0 20px;
}

.fixed-tree-sec .cardtake-test-top{
  border-bottom: 1px solid #e9e9e9;
}
.card-sorting-selected-test-cat{
    margin-top: 0px;
}
.arabic_wrapper .card-sorting-view-wrap{
    border-left: 1px solid #e9e9e9;
    border-right: 0px;
}
.arabic_wrapper .card-sorting-cat-view-wrap {
    padding-left: 30px;
    padding-right: 30px;
}
.arabic_wrapper .cardsort-view-data-repeat span img, .arabic_wrapper .cardsort-view-data-repeat span svg {
    margin-right: 0;
    width: 20px;
    margin-left: 8px;
}
.arabic_wrapper .cardtake-test-top .primary-medium svg{
 margin-right: 0px !important;
}

.top-next-btn{
 margin-left: 20px;
}
.arabic_wrapper  .top-next-btn{
    margin-left: 0px;
    margin-right: 20px;
}

.taking-test-header .gray-50-svg path {
    stroke: #fff !important;
}
.card-sorting-tests-header-wrap h4 {
    margin-bottom: 6px;
} 
.card-cat-top-wrp .heading-with-icons{
    width: calc(100% - 60px);
}
.card-cat-top-wrp h3{
    width: 100%;
}
@media screen and (max-width:1600px){
    .card-sorting-tests-header-wrap{
        padding: 16px 20px;
    }
}