.create-new-project-modal .modal-dialog{
  max-width: 602px;
  margin-top: 100px;
}
.create-new-project-modal .form-group {
    max-width: 100%;
}
.mt-8{ 
    margin-top: 8px;
}
.create-new-project-modal .switch-mamber-team{
  position: static;
  width: 100%;
}

.project-member-modal-wrap .modal-dialog{
 margin-top: 0px;
}