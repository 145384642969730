.account-used-modal-wrap .modal-dialog-centered{
    max-width: 630px;
   }
   .account-used-modal-wrap .switch-mamber-team .subtitle-2{
       max-width: calc(100% - 230px);
       margin-bottom: 0px;
   }
   .account-used-modal-wrap .switch-mamber-team{
       display: flex !important;
   }
   
   @media screen and (max-width:599px){
       .account-used-modal-wrap .switch-mamber-team {
           display: block !important;
       }
       .account-used-modal-wrap .switch-mamber-team .subtitle-2 {
           max-width: 100%;
           margin-bottom: 20px; 
       }
       
   }