/***Multiselectlist css***/
.multiselectlist-dropdown-box-container {
    border: 1px solid #9c9c9c;
    border-radius: 10px;
    padding: 13px 20px;
    cursor: pointer;
    width: 214px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
}
.multiselectlist-dropdown-selected-values-container:empty {
    display: none;
}
.multiselectlist-dropdown-box-container .w-100 p.body-text {
    color: #9c9c9c;
    position: absolute;
    transition: 0.2s all;
}
.multiselectlist-dropdown-box-container .w-100 {
    height: 55px;
    display: flex;
    padding: 13px 22px;
    padding-left: 16px;
}

.multiselectlist-dropdown-dropdown-icon-container {
    justify-content: end;
    display: flex;
    position: absolute;
    right: 10px;
    top: 15px;
}

.multiselectlist-dropdown-box-container.focussed .body-text {
    position: absolute;
    top: -9px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    padding: 0px 8px;
    background: #fff;
    color: #676767;
    left: 13px;
}

.multiselectlist-dropdown-box .number-icon,
.multiselectlist-dropdown-box .star-icon,
.multiselectlist-dropdown-box .emoticon-icon {
    border: 0px;
    border-radius: 0px;
    padding: 0;
    cursor: pointer;
    margin: 0;
    height: auto;
    width: auto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: #000;
    box-shadow: none !important;
}
.multiselectlist-dropdown-box .number-icon img,
.multiselectlist-dropdown-box .star-icon img,
.multiselectlist-dropdown-box .emoticon-icon img {
    width: 24px;
    height: 24px;
}

.multiselectlist-dropdown-box {
    width: 214px;
}

.multiselectlist-dropdown-option label {
    margin: 0;
    padding-left: 38px;
}
.multiselectlist-dropdown-selected-values-container {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
}
.multiselectlist-dropdown-option .star-icon img {
    margin-left: 2px;
}
.multiselectlist-dropdown-option .star-icon img:first-child {
    margin-left: 0px;
}
.multiselectlist-dropdown-selected-values-container img {
    margin-right: 1px;
}
.multiselectlist-dropdown-selected-values-container
    .rating-scale-icon:nth-child(4) {
    margin-right: 4px;
}
.multiselectlist-dropdown-box-container.focussed,
.multiselectlist-dropdown-box-container:hover {
    border-color: #676767;
}
.multiselectlist-dropdown-box-container.focussed
    .select-member-dropdown
    .body-text,
.multiselectlist-dropdown-box-container:hover
    .select-member-dropdown
    .body-text {
    color: #000000;
}
.multiselectlist-dropdown-option .emoticon-icon {
    position: relative;
    top: -1.5px;
}
.big-multi-select .multiselectlist-dropdown-box-container {
    width: 100%;
}
.big-multi-select {
    width: 100%;
    max-width: 400px;
}

.multiselectlist-dropdown-box-container .select-member-dropdown .body-text {
    position: static;
    display: -webkit-box; /* Required for webkit browsers */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1; /* Limits text to 1 line */
}

.select-member-dropdown span {
    display: inline-block;
    margin-right: 4px;
}

.select-create-dropdown .select-member-dropdown {
    width: calc(100% - 30px);
}
.select-create-dropdown .select-member-dropdown p {
    position: static;
    width: calc(100% - 30px);
}
.select-create-dropdown .select-member-dropdown p .selected-project-item {
    display: flex;
    align-items: center;
    max-width: 100%;
}
.select-create-dropdown .select-member-dropdown .selected-project-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 100px);
    display: block !important;
    color: #000;
}
.select-create-dropdown .select-member-dropdown .selected-project-member {
    margin-right: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
}
.multiselectlist-dropdown-box-container.project-selected {
    border-color: var(--gray-600);
}
.multiselectlist-dropdown-box-container.project-selected
    .hover-inline-svg
    svg
    path,
.multiselectlist-dropdown-box-container.project-selected
    .hover-inline-svg
    svg
    .change-stroke {
    stroke: var(--gray-900);
}
.multiselectlist-dropdown-box-container.input-active .hover-inline-svg svg path,
.multiselectlist-dropdown-box-container.input-active
    .hover-inline-svg
    svg
    .change-stroke {
    stroke: var(--gray-900);
}

.multiselectlist-dropdown-box-container.input-active
    .multiselectlist-dropdown-dropdown-icon-container
    .hover-inline-svg
    svg
    path,
.multiselectlist-dropdown-box-container.input-active
    .hover-inline-svg
    svg
    .change-stroke {
    stroke: var(--gray-700);
}

.multiselectlist-dropdown-box-container.project-selected
    .multiselectlist-dropdown-dropdown-icon-container
    .hover-inline-svg
    svg
    path,
.multiselectlist-dropdown-box-container.project-selected
    .hover-inline-svg
    svg
    .change-stroke {
    stroke: var(--gray-600);
}

.select-create-dropdown .select-member-dropdown input {
    width: 100%;
    height: 53px;
    border: 0;
    outline: none;
    box-shadow: none;
    color: #000;
}

.project-label {
    opacity: 0;
    transition: top 0.2s;
}

.input-active .project-label,
.project-selected .project-label {
    position: absolute;
    top: -8px;
    left: 10px;
    background: #fff;
    display: block !important;
    color: var(--gray-700);
    padding: 0 8px;
    transition: top 0.2s;
    opacity: 1;
}

.multiselectlist-dropdown-option {
    cursor: pointer;
}

.selected-project-member {
    margin-right: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: #9c9c9c;
}
.multiselectlist-dropdown-option > div {
    color: #676767;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.field-error .multiselectlist-dropdown-box-container {
    border-color: #ff0000 !important;
}

.select-create-dropdown .multiselectlist-dropdown-options {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    top: 58px;
    padding: 0px;
    left: 0px;
}
.select-create-dropdown .multiselectlist-dropdown-option {
    margin-bottom: 0px;
}

.select-create-dropdown .multiselectlist-dropdown-option {
    cursor: pointer;
    padding: 16px 20px;
}

.multiselectlist-dropdown-option:hover {
    background-color: #f5f5f5;
}

.multiselectlist-dropdown-box.select-create-dropdown.position-relative {
    width: auto;
}

.select-create-dropdown .arabic_wrapper .select-member-dropdown p {
    font-family: "Tajawal", sans-serif !important;
    text-align: right !important;
    direction: rtl !important;
}
.select-create-dropdown .arabic_wrapper,
.select-create-dropdown .arabic_wrapper .multiselectlist-dropdown-option {
    text-align: right !important;
    direction: rtl !important;
}
.select-create-dropdown
    .arabic_wrapper
    .multiselectlist-dropdown-dropdown-icon-container {
    left: 20px;
    right: auto;
}
.select-create-dropdown .arabic_wrapper .select-member-dropdown span {
    margin-left: 4px;
    margin-right: 0px;
}
.select-create-dropdown
    .multiselectlist-dropdown-selected-values-container
    .chip {
    margin: 8px 8px 0 0px;
    padding: 6px 8px;
}
.select-create-dropdown .multiselectlist-dropdown-option {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}
.select-create-dropdown
    .multiselectlist-dropdown-option:has(span.added-member) {
    justify-content: space-between;
}
.select-create-dropdown
    .multiselectlist-dropdown-option.disabled-option
    .added-member {
    display: flex !important;
}
.select-create-dropdown
    .multiselectlist-dropdown-option.disabled-option
    .added-member {
    display: flex !important;
}
.select-create-dropdown
    .multiselectlist-dropdown-option.active-option
    .added-member {
    display: none !important;
}
.multiselectlist-dropdown-option.disabled-option {
    cursor: not-allowed;
}
.multiselectlist-dropdown-option.disabled-option .member-label {
    opacity: 0.3;
}
.members-multiselect-dropdown .multiselectlist-dropdown-options {
    top: 57px !important;
    left: 0px;
}
.profile-form-group .multiselectlist-dropdown-box-container .body-text {
    color: var(--gray-600);
}
.form-group .select-member-dropdown .caption {
    margin-top: 0px;
}
.select-create-dropdown .item-selected-icon p.body-text{
 padding-left: 30px;
}