.task-not-completed-wrap {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    min-height:calc(100vh - 150px);
}
.task-not-completed-wrap-inner {
    max-width: 858px;
    text-align: center;
}
.task-not-completed-wrap-inner svg{
    display: table;
    margin: 0 auto;
}
.task-not-completed-wrap-inner a{
    color: #fff !important;
}