.test-list-head .text-name-heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:100%; 
}

.test-date-wrap .circular-name-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  border: 1px solid #000;
  margin-right: var(--s)
}
.moving-test-animation{
  position: relative;
}
.moving-test-animation .moving-animation-hold{
  /* background: linear-gradient(to right, #793EAB, #f08d7c); */
  
  padding: 2px;
  background-image: linear-gradient(to right, #793EAB, #ffd099, #FFB800, #E32F83, #FFB800, #FF0000);
  background-size: 600%;
  background-position: 0 0;
  border-radius: 20px;
  animation-duration: 3s;
  animation-iteration-count: 5;
  animation-name: gradients;
 
}
.moving-animation-hold{
padding: 2px;
}
.moving-test-animation .moving-animation-hold .test-list-data{
 border-radius: 19px;
}

.test-footer-lhs .circular-name-icon .tooltip-wrapper{
top: -88px;
}

.test-footer-lhs .circular-name-icon .tooltip-wrapper.top-tooltip::before {
content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 6px;
  border: 11px solid transparent;
  border-bottom: 0;
  border-top: 12px solid var(--gray-900);
  transform: translate(-50%, calc(100% + 5px));
}
.test-date-wrap .caption{
white-space:nowrap;
}


.test-footer-lhs{
  position: relative;
}

.test-footer-lhs .circular-name-icon{
  position: static; 
}

.test-footer-lhs .circular-name-icon .tooltip-wrapper {
  max-width: 230px;
  bottom: 40px;
  min-width: 110px !important;
  left: -41px !important;
  top: auto !important;
  right: auto !important;
  text-transform: initial !important;
}
.test-footer-lhs .test-date-wrap {
  position: relative;
}
.test-footer-lhs .circular-name-icon .tooltip-wrapper::before{
  left: 53px !important;
}

@keyframes gradients {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 50% 0;
  }
  50% {
    background-position: 90% 0;
  }
  60% {
    background-position: 60%;
  }
  75% {
    background-position: 40%;
  }
  100% {
    background-position: 0 0;
  }
}


.loader-1.center {
  display: block;
  position: absolute;
  top: 0;
  left: -30px;
  bottom: 0;
  right: 0;
  margin: auto;
  zoom:0.8;
}
.loader-1 {
  height: 30px;
  width: 30px;
  -webkit-animation: loader-1-1 4.8s linear infinite;
  animation: loader-1-1 4.8s linear infinite;
}
.loader-1 span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 30px;
  width: 30px;
  clip: rect(0, 32px, 32px, 16px);
  border: 4px solid #676767;
  border-radius: 50%; 
  -webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}


/* Loader 1 */
.loader-1 {
	height: 30px;
	width: 30px;
	-webkit-animation: loader-1-1 4.8s linear infinite;
	        animation: loader-1-1 4.8s linear infinite;
}
@-webkit-keyframes loader-1-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-1-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-1 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 30px;
	width: 30px;
	clip: rect(0, 32px, 32px, 16px);
	-webkit-animation: loader-1-2 1.2s linear infinite;
	        animation: loader-1-2 1.2s linear infinite;
}
@-webkit-keyframes loader-1-2 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader-1-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(220deg); }
}
.loader-1 span::after {
	content: "";
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 30px;
	width: 30px;
	clip: rect(0, 32px, 32px, 16px);
	border: 4px solid #676767;
	border-radius: 50%;
	-webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
	        animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-1-3 {
	0%   { -webkit-transform: rotate(-140deg); }
	50%  { -webkit-transform: rotate(-160deg); }
	100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader-1-3 {
	0%   { transform: rotate(-140deg); }
	50%  { transform: rotate(-160deg); }
	100% { transform: rotate(140deg); }
}


.test-list-repeat-wrap {
  width: 25%;
  padding: 9.5px;
}

.test-list-data {
  background: var(--gray-200);
  border-radius: var(--xl);
  padding:var(--xl); 
  height: 233px;
  position:relative; 
  overflow:hidden;
  min-width: 300px;
}

.test-list-data a{
  text-decoration:none !important;	
}

.test-list-data .position-relative{
  z-index:1;	
}
.test-list-data:after {
  content: "";
  position: absolute;
  width: 200px;
  height: 130px;
  background: #FFE2C0;
  opacity: 0.85;
  filter: blur(37.3397px);
  -webkit-backdrop-filter: blur(37.3397px);
  border-radius: 20px;
  left: -13.83%;
  right: 53.41%;
  top: 47.44%;
  bottom: 0px;
  transition:0.3s;
}
.test-list-data:hover:after{
  width: 282px;
  height:240px;	
  background: #FFE2C0;
  filter: blur(37.3397px);
  -webkit-backdrop-filter: blur(37.3397px);
  border-radius: 20px;
  left: -17.84%;
  right: 35.38%;
  top: 24.89%;
  bottom: -27.9%;	
}

.live-test:after {
  content: "";
  position: absolute;
  width: 200px;
  height: 130px;
  background: #FF9BCA;
  opacity: 0.85;
  filter: blur(37.3397px);
  border-radius: 20px;
  left: -13.83%;
  right: 53.41%;
  top: 47.44%;
  bottom: 0px;
}

.live-test:hover:after{
  width: 282px;
  height:240px;	
  background: #FF9BCA;
  filter: blur(37.3397px);
  border-radius: 20px;
  left: -17.84%;
  right: 35.38%;
  top: 24.89%;
  bottom: -27.9%;	
}

.completed-test:after{
  content: "";
  position: absolute;
  width: 200px;
  height: 130px;
  background: #BF8EE8;
  opacity: 0.85;
  filter: blur(37.3397px);
  left: -13.83%;
  right: 53.41%;
  top: 47.44%;
  bottom: 0px;	
}

.completed-test:hover:after{
  width: 282px;
  height:240px;	
  background: #BF8EE8;
  filter: blur(37.3397px);
  border-radius: 20px;
  left: -17.84%;
  right: 35.38%;
  top: 24.89%;
  bottom: -27.9%;	
}

.test-footer-data {
  padding-top:0px;
}
.test-list-repeat-wrap .all-test a {
  width: 100%;
  position:relative; 
  z-index:1;
}
.card-middle-link {
  padding-bottom: var(--l);
  height: 101px;
  display: flex;
  align-items: flex-end;

}
.card-load-mid{
  margin-top:34px;
  margin-bottom:10px;
  min-height:40px;  
}
.card-load-mid span{
  margin:0px !important;
}
.card-load-mid .react-loading-skeleton{
  margin-top:0px !important;
}


.status-tag{
 font-size: var(--m) !important;
 line-height: 16px !important;
 padding:var(--xs) var(--m);
 height: 24px;
}

.test-footer-lhs{
  width: calc(100% - 45px);
}
.test-card-footer-lhs-data .status-tag{
  margin-right:var(--s)
}
.test-footer-rhs {
  width: 50px;
}
.test-footer-rhs .menu-item svg {
  width: 16px;
}

.loading-cards .card-middle-link{
  height: 90px;
}

.Ai_icon:hover .tooltip-wrapper {
  display: block;
  margin: 0;
  white-space: nowrap;
  bottom: 50px;
  width: auto;
  right: -54px;
}

@media screen and (max-width: 1279px) {
  .test-list-repeat-wrap, .creat-test-card-repeat {
      width: 33.333%;
  }
}

@media screen and (max-width: 991px) {
  .test-list-repeat-wrap, .creat-test-card-repeat {
      width: 50%;
  }
}
@media screen and (max-width: 699px) {
  .test-list-repeat-wrap, .creat-test-card-repeat {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
  }
}