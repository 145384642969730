.user-all-workspaces-list {
    max-width: 750px;
}

.user-all-workspaces-list .span-workspace-owner-icon {
    background-color: #000;
}

.user-all-workspaces-list .workspace-type-info {
    color: #000;
}

.user-all-workspaces-list .span-workspace-owner-icon {
    margin-right: 12px;
}

.user-all-workspaces-list .workspace-type-info {
    margin: 0px;
}

.user-all-workspaces-list .table tbody tr:hover {
    background: #ffffff;
}

.workspace-list-table {
    max-width: 100%;
}
.dropdown-disabled{
    opacity: 0.5;
    pointer-events: none;
}

.workspace-list-table table {
    min-width: 660px;
}

.user-all-workspaces-list .table tr th,
.user-all-workspaces-list .table td {
    padding-left: 7px;
    padding-right: 0px;
}
.user-all-workspaces-list .table td:nth-child(2){
  text-transform: capitalize;
} 

.user-all-workspaces-list .table tr th {
    padding-top: 0px;
}

.user-all-workspaces-list .header-info-type {
    display: block;
    color: #676767;
}

.user-all-workspaces-list .invite-pending .span-workspace-owner-icon {
    background-color: #ccc;
}

.user-all-workspaces-list .invite-pending td {
    color: #ccc;
}

.accepet-button .secondary-btn {
    margin-left: 12px;
}

.user-all-workspaces-list .tableDropdown {
    width: 216px;
}

.user-all-workspaces-list .workspace-role-col {
    justify-content: start;
}

.user-all-workspaces-list table thead th:nth-child(2) {
    width: 180px;
}
.user-all-workspaces-list .accepet-button .primary-small{
 width: 109.09px;
 height: 40.24px;
}

.user-all-workspaces-list .accepet-button .secondary-small{
    width: 110.97px;
    height: 40.24px;  
}

.user-all-workspaces-list  table .tableDropdown .workspace-role-col span{
min-width: 83.19px;
}
.user-all-workspaces-list .dropdown span{
    min-width: auto !important;
}
.rs-inner-profile-wrapper .formbox .change-email-option button.secondary-btn.secondary-small {
    position: static;
    right: 0;
    top: 0%;
    transform: translateY(0%);
}

.active-workspace {
    position: relative;
}

.active-workspace .span-workspace-owner-icon:after {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    background: #378B10;
    top: -4px;
    left: -5px;
    border-radius: 100%;
    border: 2px solid #fff;
}

.workspace-list-table table td {
    font-size: 14px;
    font-weight: 500;
}

@media screen and (max-width: 1199px){
    .workspace-list-table {
        overflow: auto;
    }
}