
.session-loged-out-data {
    min-height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: center;
}

.session-log-out-inner-data {
    max-width: 574px;
    text-align: center;
}

.session-log-out-inner-data .switch-mamber-team{
    text-align: left;
}
.session-log-out-inner-data .switch-mamber-team .body-text-1{
    max-width: 286px;
}
.header-with-logo-only{
    padding: 15px !important;
}
.session-log-out-inner-data .primary-large, .session-log-out-inner-data .primary-large:hover{
  color: #fff;
}

.session-log-out-inner-data .switch-mamber-team .primary-medium, .session-log-out-inner-data .switch-mamber-team .primary-medium:hover{
 background-color: #fff !important;
 color: #000 !important; 
 border: 0px !important;
}


@media screen and (max-width:1199px) {
    .session-log-out-inner-data .switch-mamber-team .body-text-1{
        max-width: 100%;
        margin-bottom: 20px;
    }
}
