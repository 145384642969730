.downgrad-workspace-modal .modal-dialog {
    max-width: 585px;
}

.help-box-wrap .link-text {
    font-size: 14px;
    line-height: 25px;
}

.downgrade-options-checklist ul {
    margin: 0;
    padding: 0;
}

.downgrade-options-checklist ul li svg {
    margin-right: 8px;
}

.downgrad-workspace-modal .multiselectlist-dropdown-box {
    width: 100%;
}

.multiselectlist-dropdown-box-container {
    width: 100%;
}

.downgrad-workspace-modal .form-group {
    max-width: 100%;
}

a.link-text {
    color: #9759CB
}

.confirm-downgrade-btn .secondary-large {
    min-width: 232.98px;
}
