.delete-account-progress-wrap {
    display: flex;
    align-items: center;
}

.delete-account-wrap {
    text-align: center;
    display: table;
    margin: 0 auto;
}

.account-delete-icon svg {
    display: table;
    margin: 0 auto;
    width: 80px;
    height: 80px;
}

.delete-account-progress-wrap .progress-bar {
    left: 50%;
    max-width: 542px;
    position: static;
    top: 50%;
    width: 542px;
    transform: unset;
    height: 10px;
}

.delete-account-progress-wrap .progress-bar .bar {
    background-color: #D9D9D9 !important;
}

.delete-account-progress-wrap .progress-bar .progress {
    background: #000000;
    transition: 0.3s;
}

@media screen and (max-width:767px) {
    .delete-account-progress-wrap .progress-bar {
        max-width: 300px;
        width: 300px;
    }
}