.delete-workspace-modal .modal-dialog {
    max-width: 620px;
}

.delete-workspace-modal .form-group {
    max-width: 100%;
}

.red-bg-wrap {
    border-radius: 5px;
    padding: 16px;
    background-color: #ff0000;
    color: #fff;
}

.red-bg-wrap p {
    color: #fff;
}

.delete-project-modal .primary-large{
    min-width: 215.03px;
}