.prefer-design-wrapper{
    padding: 25px 25px 25px 0px;
    border: 1px solid #e9e9e9;
    box-shadow: none !important;
    /* transition: 0.3s; */
}
.prefer-design-wrapper.add_design {
    padding: 0;
    border: 0; 
    cursor: default !important;
    background-color: unset !important;
}
.prefer-design-wrapper .prefer-designpreview-thum-data-wrap{
    cursor: default;
}
.prefer-design-wrapper.add_design .preference_to_add_design{
 height: 100%;
}
.prefer-design-wrapper.add_design .upload-image-inner-wrapper{
    width: 100%;
    max-width: 100%;
}

.prefer-design-wrapper.draggable-inner-element-start{
    background-color: #EFDCFF    !important;
    border: 1px solid #EFDCFF !important;
    border-radius: 10px;
    transform:rotate(-10deg) !important;
}

.prefer-design-wrapper .upload-image-hold{
    width: 100% !important;
    padding: 0px !important;
}
.prefer-design-wrapper .added-design-preview-thum{
    background: unset !important;
    border: 0px !important;
}
.prefer-design-wrapper .prefer-designpreview-thum-data-wrap{
    padding: 0px !important;
}
.prefer-design-wrapper .added-design-preview-thum{
  max-width: 100%;
}