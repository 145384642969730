
.question-image-wrap {
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.question-input-img-thum {
    width: 50px;
    height: 50px;
    border-radius: 5px; 
    object-fit: contain;
    background: #f2f2f2;
}
.question-setting-action{
    position: absolute;
    background: #000000a3;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}
.question-image-wrap:hover .question-setting-action{
 display:flex;
}
.question-image-wrap .hamburger-items{
    min-width: 226px;
    left: 37px !important;
    top: -14px !important;
}
.question-image-wrap .hamburger-items .dropdown-item{
   display: flex;
   align-items:center; 
} 
.question-image-wrap .dropdown-item img, .question-image-wrap .dropdown-item svg{
    margin-right: 8px;
}
.red-text {
    color: #FF0000 !important;
}