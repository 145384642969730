.accept-workspace-modal .modal-dialog {
    max-width: 475px;
    width: calc(100% - 15px);
}

.accept-workspace-modal .active-plan-modal-btn button {
    width: auto;
}

.accepet-modal-text {
    text-align: center;
}

/***Compare plan modal css***/
.compare-modal-wrap .modal-dialog {
    max-width: 1070px;
}

.compare-modal-wrap .plan-options-data {
    min-height: 100%;
}

.compare-modal-wrap .plan-options-repeat {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
}

/***billing info modal css***/
.billing-info-modal .modal-dialog{
    max-width: 714px;
}
.billing-info-modal .form-group {
    max-width: 100%;
}
.billing-info-modal .formbuttombox .btn.primary-large {
    margin-right: 32px;
}
.billing-info-modal .primary-large{
    width: 118px;
}

@media screen and (max-width:767px) {
    .compare-modal-wrap .plan-options-repeat {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }

    .compare-modal-wrap .plan-options-repeat:last-child {
        margin-bottom: 0px;
    }
    .billing-info-modal .formbuttombox .btn.primary-large {
        margin-right: 15px;
    }
}