.change-billing-cycle-modal .modal-dialog{
    max-width: 570px;
    }
    
    .change-billing-cycle-modal .radio-btn-wrap{
        padding-left: 43px;
    }
    
    .change-billing-cycle-modal .radio-btn-label {
        color:#000;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }
    .change-billing-cycle-modal .radio-btn-wrap .checkmark{
      margin-top: 12px;
    }
    .current-cycle-wrap {
        padding-left: 43px;
        margin-bottom: 12px;
    }
    .current-cycle-wrap span svg{
        margin-right: 4px;
    }
    .next-bill-data {
        padding-left: 43px;
    }
    .billing-cycle-radio-wrap label {
        display: block;
    }
    .billing-cycle-change-price{
        margin-top: -3px;
    }
    .billing-change-modal-data .box-large {
        padding: 16px;
    }
    .billing-change-modal-data .current-cycle-wrap .caption, 
    .billing-change-modal-data .next-bill-data .caption{
      color: #676767;
    }
    .billing-change-modal-data .active-box .current-cycle-wrap .caption, 
    .billing-change-modal-data .active-box .next-bill-data .caption{
        color: #000;
    }
    
    .billing-change-modal-data .box-large:hover .current-cycle-wrap .caption,
    .billing-change-modal-data .box-large:hover .next-bill-data .caption{
        color: #000;
    }

    .billing-change-modal-data .box-large:hover {
        border-color: #000;
    }


    .billing-change-modal-data .green-color {
        color: #378B10 !important;
    }
    .active-box {
        border-color: #000;
    }
    
    @media screen and (max-width: 360px) {
        .billing-change-modal-data .box-large {
            padding: 10px;
        }
        .change-billing-cycle-modal .radio-btn-wrap, .current-cycle-wrap, .next-bill-data{
            padding-left: 33px;
        }
    }
    