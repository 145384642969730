.back-to-dashboard a {
    margin-right: 20px;
}
.folder-icon {
    margin-right: 20px;
}
.mamber-added.d-flex.align-items-center {
    margin-left: 20px;
    cursor: pointer;
}
.mamber-added.d-flex.align-items-center p {
    margin: 0 0 0 8px;
}
.test-dashboard-search-right {
    max-width: 440px;
    position: relative;
}
.test-dashboard-search-right .search-functionlity-data.search-for-desktop {
    position: absolute;
    right: 100%;
    background: #fff;
    width: auto;
}
.test-dashboard-title-left {
    width: calc(100% - 440px);
}
.test-dashboard-title-left .heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 440px);
}

@media screen and (max-width: 1024px) {
    .test-dashboard-title-left .heading {
        max-width: calc(100% - 110px);
    }
}

@media screen and (max-width: 767px) {
    .test-dashboard-title-left .tag {
        display: none;
    }
    .test-dashboard-title-left {
        width: calc(100% - 92px);
        display: flex !important;
    }

    .back-to-dashboard {
        display: none;
    }
    .test-dashboard-title-left .heading {
        max-width: calc(100% - 110px);
        font-size: 22px;
    }
    .mamber-added.d-flex.align-items-center p {
        top: 0px;
    }
    .mamber-added.d-flex.align-items-center {
        margin-left: 12px;
    }
    .test-dashboard-search-right {
        top: 0px;
    }
}
