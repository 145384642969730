.modal-center-list {
    text-align: left;
    max-width: 90%;
    display: block;
    margin: 0px auto;
}
.modal-center-list li {
    padding: 0px;
    font-size: 16px;
    line-height: 25px;
    color: #676767;
    font-weight: 500;
}
.tester-id-verified-modal-data.width-md {
    max-width: 90%;
    display: block;
    margin: auto;
}
@media screen and (max-width: 480px) {
    .modal-center-list li {
        padding: 0px;
        font-size: 14px;
    }
}
