.test-length-wrap{
    margin-left: var(--xl);
}
.result-test-name{
    margin-right: var(--2XL);
}
.result-member-icon{
    margin-right: 30px;
}
.test-name-wrap .subtitle-2   {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: max-content;
}
.create-test-header .test-header-tags a {
    display: inline-block;
}
.test-results-header-researcher .test-name-wrap .subtitle-2 {
    max-width: 440px;
}
.test-results-header-researcher .project-name-link {
    display: inline-block;
}


.completed-test-header .result-member-icon {
    margin-right: 0;
}

@media screen and (max-width:1350px) {
    .test-results-header-researcher .test-name-wrap .subtitle-2 {
        max-width: 270px;
    }
    .completed-test-header .test-name-wrap .subtitle-2 {
        max-width: 240px;
    }
  
}



@media screen and (max-width:1200px) {
    .create-test-header .test-name-wrap .subtitle-2{
        max-width: 110px;
    }
    .project-name-mobile{
        display: inline-flex !important;
    }
}
@media screen and (max-width:1150px) and (min-width:992px) {
  .len-tag{
    display: none;
  }
  .completed-test-header .project-name-link {
    display: none;
    }

    .test-results-header-researcher .test-name-wrap .subtitle-2 {
        max-width: 160px;
    }

}

@media screen and (max-width:991px) {
    .completed-test-header .test-name-wrap .subtitle-2 {
        max-width: 100%;
    }
    .test-results-header-researcher .test-name-wrap .subtitle-2 {
        max-width: 100%;
    }
    .test-header-tags.test-header-tag-for-mobile.none-for-desktop.show-below-tab {
        display: flex !important;
        margin-bottom: var(--xl);
        justify-content: space-between;
    }
    .test-results-header-researcher{
        display: block;
    }
    .test-result-header-wrapper .test-header-left-data .test-header-tags .tag
    , .test-result-header-wrapper .test-header-left-data .status-tag,
    .test-result-header-wrapper .test-header-left-data .completed-date-text{
        display: none;
    }
    
    .test-results-header-researcher .test-header-right-data{
      display: none;
    }
    .test-result-header-hamburger-menu{
        display: block !important;
    }
    .test-result-header-hamburger-menu  .dropdown-item{
        display:block;
    }
    .completed-test-header .test-header-left-data {
        width: 100%;
        justify-content: space-between;
    }
    .live-test-header .test-header-left-data {
        width: 100%;
        justify-content: space-between;
    }
    .test-result-header-hamburger-menu .hamburger-items .dropdown-item {
        display: flex !important;
        align-items: center !important;
    }
    .test-result-header-hamburger-menu .hamburger-items .dropdown-item img, .test-result-header-hamburger-menu .hamburger-items .dropdown-item svg {
        margin-right: 10px;
    }
    .completed-ended-data {
        display: block;
        padding: 18px 20px;
    }
    .test-result-header-hamburger-menu .hamburger-items{
        right: 0 !important;
        top: 30px !important;
        width: 250px;
    }
    .test-result-header-wrapper .test-header-tags {
        margin: 0px;
        display: block;
    }
    .test-result-header-wrapper .test-name-wrap {
        width: 100%;
        max-width: unset !important;
    }

    .create-test-header .show-below-tab .test-header-tags{
        display: block !important;
    }
    .three-column-layout-top-header.create-test-header {
        display: block;
    }
    .create-test-header  .test-header-tags.test-header-tag-for-mobile.none-for-desktop.show-below-tab{
     margin-left: 0px;
     margin-right: 0px;
    }
    .create-test-header  .test-header-left-data .test-header-tags{
        display: none;
    }
    .test-lbs{
        display: none !important;
    }
    .create-test-header .test-name-wrap .subtitle-2 {
        max-width: 380px;
    }
    .test-length-wrap .tooltip-wrapper{
        display: none;
    }
    .length-icon {
        margin-right: 8px;
        margin-left: 0px;
    }
    .create-test-header .test-result-header-hamburger-menu{
        display: none !important;
    }
    .project-name-link{
        display: none !important;
    }
}

@media screen and (max-width:767px) {
    .back-arrow-btn{
        margin-right: var(--l);
    }

    .create-test-header .test-name-wrap {
        width: calc(100% - 60px);
        justify-content: space-between;
        max-width: 100%;
        padding-right: 0;
    }
    .test-header-tags .tag {
        margin-right: var(--s);
    }
    .create-test-header .test-result-header-hamburger-menu{
        display: block !important;
    }
}
@media screen and (max-width:640px) {
    .completed-date-text{
     display: none;
    }

    .test-length-wrap .caption{
        display: none;
    }

}

@media screen and (max-width:410px) {
    .test-header-tags .tag {
       max-width: 102px;
    }
}

@media screen and (max-width:370px) {
    .test-header-tags .tag {
        max-width: 93px;
    }
}

@media screen and (max-width:350px) {
    .test-header-tags .tag {
        max-width: 73px;
    }
}


