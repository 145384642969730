.tag{
    padding: var(--xs) var(--s);
    line-height: 16px;
    min-height: auto;
}
.tag svg{
    width: 16px;
    height: 16px;
}
.tag-icon-left, .tag-icon-right{
    display: inline-flex;
    align-items: center;
}

.ltr-icon{
 margin-right: var(--xs);
} 

.rtl-icon{
    margin-left:var(--xs);
} 

.large-tag {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}
.large-tag svg{
    width: 24px;
    height: 24px;
}
.gray-tag{
    background-color: #fff;
    color:var(--gray-700);
    border-color:var(--gray-600) !important;
}
.blue-tag{
    border-color:#2151F5;
    background:#ACBEFB;
   color: #000;
}
.tag  svg path{
    stroke: var(--gray-50);
}
.blue-tag svg path, .pink-tag svg path, .purple-tag svg path, .gold-tag svg path{
    stroke: var(--gray-900);
}
.gray-tag svg path{
stroke: var(--gray-700);
}
.navy-tag{
    border-color: #2151F5;
    background: #2151F5;
    color: #ffffff;
}